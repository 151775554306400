<template>
  <div> {{ motif }}</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {object: {type: Object, required: true}},
    data(){
        return  {

        }
    },
    methods: {
        
    },
    computed: {
      ...mapGetters({
        motifs: 'sanitaire/motifConsultations'
      }),
      motif(){
        if(this.object !== null) {
          let m = this.motifs.find(item => item.uid === this.object.motif)
          if(m) return m.libelle
          return '-'
        }
        return '-'
      }
    }


}
</script>

<style lang="scss" scoped>
.actions{
    a{
        margin-left: 5px;
    }
}
</style>